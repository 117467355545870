@tailwind base;
@tailwind components;
@tailwind utilities;
body {
    cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='35' height='48' viewBox='0 0 49 62'%3E%3Cpath fill='%23BEF3FE' d='M48.06 39.546 2.14.38C1.424-.232.337.395.508 1.32l10.96 59.35c.168.903 1.36 1.123 1.838.34l11.406-18.694a1 1 0 0 1 .83-.48l21.893-.53c.917-.023 1.322-1.165.624-1.76Z'/%3E%3C/svg%3E") 0 0, auto;
}
a {
    cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='32' viewBox='0 0 49 62'%3E%3Cpath fill='%23BEF3FE' d='M48.06 39.546 2.14.38C1.424-.232.337.395.508 1.32l10.96 59.35c.168.903 1.36 1.123 1.838.34l11.406-18.694a1 1 0 0 1 .83-.48l21.893-.53c.917-.023 1.322-1.165.624-1.76Z'/%3E%3C/svg%3E") 0 0, auto;

}

button{
    cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='32' viewBox='0 0 49 62'%3E%3Cpath fill='%23BEF3FE' d='M48.06 39.546 2.14.38C1.424-.232.337.395.508 1.32l10.96 59.35c.168.903 1.36 1.123 1.838.34l11.406-18.694a1 1 0 0 1 .83-.48l21.893-.53c.917-.023 1.322-1.165.624-1.76Z'/%3E%3C/svg%3E") 0 0, auto;
}

img,video,svg{
    cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10'%3E%3Ccircle cx='5' cy='5' r='5' fill='white' /%3E%3C/svg%3E") 5 5, auto;
}


#socials{
    cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40'%3E%3Ccircle cx='20' cy='20' r='20' fill='white' /%3E%3C/svg%3E") 20 20, auto;
}
/* *,img,video{ */
    /* cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10'%3E%3Ccircle cx='5' cy='5' r='5' fill='white' /%3E%3C/svg%3E") 5 5, auto; */
  /* } */
*{
    /* cursor: none; */
    user-select:none;
    /* scroll-behavior:smootb; */
}

/* This will apply to everything */
/* * {
    mix-blend-mode: difference;
}
img {

    mix-blend-mode: normal;
}  */